<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 13.359 13.359"
  >
    <path
      :style="iconStyle"
      d="M16.68,9.165H6.535l4.676-4.676L10,3.32,3.32,10,10,16.68l1.169-1.169L6.535,10.835H16.68Z"
      transform="translate(-3.32 -3.32)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '13.359',
    },
    width: {
      type: String,
      default: '13.359',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
